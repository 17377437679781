<template>
  <div class="jsmb-account-information-detail">
    <h2 class="jsmb-title-detail is-size-4 has-text-text">CGU</h2>


    

    
  </div>
</template>

<script>
export default {
  name: 'Cgu',
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL,
      canonical = this.$router.resolve({ name: 'Cgu' });

    let title = (siteName+' - Conditions générales d\'utilisation - Stockage de bateaux chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! La première plateforme dans le monde mettant en relation des propriétaires d\'un jardin, d\'un abri, d\'un box ou d\'un garage, avec des plaisanciers qui recherchent un endroit où stocker leur bateau.';

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
          { rel: 'canonical', href: (baseUrl+canonical.href) },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': (baseUrl+this.$route.fullPath),
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'name': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'name': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
       {
          'name': 'twitter:image',
          'content': (baseUrl+require('./../../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  input:not([type="radio"]){
    margin-bottom: 0.5rem;
    border:none;
    box-shadow: none;
  }
  textarea {
    box-shadow: none;
    border:none
  }
  input[type=radio] {
  border: 1px solid #df5051;
  padding: 0.5em;
  -webkit-appearance: none;
}
input[type=radio]:checked {
  background-size: 9px 9px;
  background-color: #df5051;
}
input[type=radio]:focus {
  outline-color: none;
}
.radio {
  font-size:0.85rem
}


</style>
